<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <span class="hello">Почему стоит выбрать курс «ОПОРЫ РОССИИ»?</span>
                <h2>Стабильное направление</h2>
                <p>
                    В то время как многие страны отказались работать с Россией, Китай стал самым надежным экономическим партнером
                </p>

                <h2>Старт с любого капитала</h2>
                <p>
                    Думаете нужны миллионные бюджеты? Нет. Все зависит от товара, который вы выбираете
                </p>

                <a (click)="onClick('what_is_in_package')" class="btn btn-primary view-work">Начать</a>
                <!--<a (click)="onClick('pricing')" class="btn btn-primary">Купить</a>-->
            </div>
        </div>
    </div>
</div>
