<div id="what_is_in_package" class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Формат <span>Обучения</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fab fa-linode"></i>
                    <h3>13 образовательных модулей</h3>
                    <p>
                        Программа обучения позволит вам самостоятельно разобраться во всех тонкостях работы с Китаем. 
                    </p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fas fa-file-pen"></i>
                    <h3>Дополнительные материалы</h3>
                    <p>
                        Дополнительные материалы, чек-листы, инструкции, видеопособия, образцы документов для самостоятельной работы с Китаем.
                    </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fas fa-users"></i>
                    <h3>Доступ в закрытый чат</h3>
                    <p>Чат в Телеграм для оперативной связи и обсуждения актуальных вопросов участников в ходе обучения.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fas fa-user-check"></i>
                    <h3>Контакты проверенных агентов</h3>
                    <p>База проверенных агентов, посредников, надежных логистических компаний, сертифицированных органов.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fas fa-book"></i>
                    <h3>Карта АТЭС</h3>
                    <p>При приобретении тарифа VIP вы получите в подарок карту АТЭС для безвизовых поездок в Китай и 16 других стран содружества. Рыночная цена карты АТЭС от 40 тыс. рублей.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are education_consistent_container">
                    <i class="fas fa-list"></i>
                    <h3>Офлайн/Онлайн-мероприятия </h3>
                    <p>Встречи с учениками в онлайн/офлайн формате для разбора ошибок, ответов на вопросы и поддержки во время обучения.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
