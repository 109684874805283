<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2><span>Отзывы</span></h2>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="row">
                        <div class="single-blog-item feedbackContainer">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                                <div class="icon-container" style="flex: 0 0 30%;">
                                    <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                                </div>
                                <div class="text-container" style="flex: 1;">
                                    <h3>Василий Кузовков</h3>
                                    <span></span>
                                </div>
                            </div>
                            <p>”Информация полезна и информативна. Доступно объяснили ключевые аспекты взаимодействия с китайскими партнерами, что особенно важно для тех, кто только начинает работать с Китаем. Хорошая возможность для развития.”</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Валерия Тарханова</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Бизнес-миссия в Китай помогла глубже понять особенности рынка. Организация встреч с местными предпринимателями и посещение производств дали новые идеи для работы. Это точно полезный опыт, который стоит попробовать.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Евгений Нашиванко</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Погружение в Китай дало возможность упорядочить мои знания и получить новые инсайты. Много внимания уделялось практическим вопросам, что делает материал особенно ценным для работы.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Максим Ледовый</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Поездка в Китай была полезной: познакомился с потенциальными партнерами, обсудил условия сотрудничества. Организация мероприятия была четкой, всё прошло без накладок. Хорошая возможность для установления деловых связей.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Наталья Палкина</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Материалы хорошо структурированы, информация подана доступно. Кураторы делились практическими примерами, что очень помогло понять особенности китайского бизнеса. Полезно и актуально.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Вахид Сафаров</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Бизнес-миссия была грамотно организована. Мы смогли увидеть производство и провести переговоры с поставщиками. Это помогло понять, на что нужно обращать внимание при планировании сотрудничества.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Елена Маляренко</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Взаимодействие дало возможность увидеть китайский бизнес изнутри. Практический материал, полезная информация. Посещение фабрик и переговоры с местными предпринимателями помогли лучше понять структуру рынка. В целом, опыт оказался полезным.”</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item feedbackContainer">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center topFeedbackInfo">
                            <div class="icon-container" style="flex: 0 0 30%;">
                                <i class="fas fa-file-pen" style="font-size: 33px;"></i>
                            </div>
                            <div class="text-container" style="flex: 1;">
                                <h3>Наталья Ледовая</h3>
                                <span></span>
                            </div>
                        </div>
                        <p>”Много полезной информации в плане понимания культурных особенностей и правил ведения переговоров. Всё четко и по делу. Для тех, кто планирует выйти на китайский рынок, это хороший старт.”</p>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>


<!--<ng-template carouselSlide>
    <div class="single-blog-item bg5">
        <span>Game</span>
        <h3><a routerLink="/blog-details">How to Be a Work From Home Professional</a></h3>
        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
    </div>
</ng-template>-->
