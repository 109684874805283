<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Эксперты <span>курса</span></h2>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">

                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/experts/big_size/maslov.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">
                                Маслов Алексей Александрович
                            </h3>
                            <span class="post">
                                Профессор, доктор исторических наук
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <li>
                                    <p>
                                        Преподаватель высшей школы бизнеса МГУ по программе Doing Business with China
                                        Директор института стран Азии и Африки
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <!--<ng-template carouselSlide>
        <div class="single-team-box">
            <div class="image">
                <img src="assets/img/experts/big_size/borisov.jpg" alt="team-img">
            </div>
            <div class="content">
                <h3 class="title">
                    Борисов Сергей Ренатович
                </h3>
                <span class="post">
                    Председатель попечительского совета «ОПОРЫ РОССИИ»
                </span>
            </div>
            <div class="social">
                <ul>
                    <li>
                        <p>
                            Председатель попечительского совета Общероссийской общественной организации малого и среднего предпринимательства «ОПОРА РОССИИ», заместитель председателя Общественного совета при ФНС России
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="single-team-box">
            <div class="image">
                <img src="assets/img/experts/big_size/kalinin_2.jpg" alt="team-img">
            </div>
            <div class="content">
                <h3 class="title">
                    Калинин Александр Сергеевич
                </h3>
                <span class="post">Президент «ОПОРЫ РОССИИ»</span>
            </div>
            <div class="social">
                <ul>
                    <li>
                        <p>
                            Президент Общероссийской общественной организация малого и среднего предпринимательства «ОПОРА РОССИИ»
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
        -->
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/experts/big_size/ilona.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">
                                Горшенева-Долунц Илона
                            </h3>
                            <span class="post">
                                Кандидат философских наук
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <li>
                                    <p>
                                        Преподаватель высшей школы бизнеса МГУ по программе Doing Business with China
                                        Предприниматель. 13 лет жизни и работы в Китае
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/experts/big_size/yuanyi.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">
                                Юань И
                            </h3>
                            <span class="post">
                                Юридическая фирма DHH
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <li>
                                    <p>
                                        Старший партнер московского офиса
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/experts/big_size/tanshu.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">
                                Сунь Тяньшу
                            </h3>
                            <span class="post">
                                Платформа цифровой торговли КИФА
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <li>
                                    <p>
                                        Основатель и председатель совета директоров ПАО «КИФА», российско-китайской платформы для цифровой торговли.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-template>

                <!--<ng-template carouselSlide>
        <div class="single-team-box">
            <div class="image">
                <img src="assets/img/experts/big_size/zanigabdinov.jpg" alt="team-img">
            </div>
            <div class="content">
                <h3 class="title">
                    Зайнигабдинов Александр
                </h3>
                <span class="post">Руководитель пекинского офиса CHINA WINDOW CONSULTING GROUP</span>
            </div>
            <div class="social">
                <ul>
                    <li>
                        <p>
                            Руководитель пекинского офиса Консалтинговой компании CHINA WINDOW CONSULTING GROUP, арбитр Shanghai International Arbitration Center
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </ng-template>
    -->
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
