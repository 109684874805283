import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'diploms',
    templateUrl: './diploms.component.html',
    styleUrls: ['./diploms.component.scss']
})
export class DiplomsComponent implements OnInit {

    constructor() {}

    ngOnInit() {}

}
