<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Какие преимущества дает <span>Китай</span> для российского бизнеса?</h2>
            <!--<p>
                Китай сегодня - это всемирная фабрика по производству абсолютно всего!
            </p>-->
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content ">
                    <!--
                    <div class="section-title">
                        <h2>Китай – главный торговый <span>партнер России!</span></h2>
                        <p>
                            Многие предприниматели хотели бы наладить торговлю с Китаем напрямую.
                            Однако далеко не все делают первый шаг: не знают, с чего начать или же чувствуют неуверенность.
                            Чужая страна, сложный незнакомый язык, азиатский менталитет, трудности с таможенным оформлением, большие расстояния, стереотипы о низком качестве – вот основные страхи российского бизнесмена.
                        </p>
                    </div>
                    -->
                    <div class="section-title">
                        <p class="textHighlight">
                            Китай - главный торговый партнер России. Выходя на рынки КНР вы получаете: 
                        </p>
                    </div>
                    <ul class="features-list-one-line">
                        <li>
                            <i class="fas fa-check"></i>Возможность открыть компанию и продавать продукцию на весь мир
                        </li>
                        <li><i class="fas fa-check"></i>Огромный выбор товаров, станков, оборудования, линий</li>
                        <li><i class="fas fa-check"></i>Возможность начать бизнес с минимальными вложениями</li>
                        <li>
                            <i class="fas fa-check"></i>Организация контрактного OEM и ODM производства
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Возможность модернизации производства
                        </li>
                        <li><i class="fas fa-check"></i>Самые низкие цены</li>
                        <li><i class="fas fa-check"></i>Возможность обойти санкции</li>
                        <li>
                            <i class="fas fa-check"></i>Возможность брендирования продукции
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Организация поставок более дешевых и качественных комплектующих
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Возможность организовать посреднический бизнес без вложений
                        </li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
