<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Образовательный курс: торговля с Китаем</h4>
            <h2><span>Чему вы научитесь на курсе?</span></h2>
            <!--<p>
                «ОПОРА РОССИИ» запускает обучающий бизнес-курс с практическими заданиями, после которого вы научитесь самостоятельно работать с Поднебесной!
            </p>
            -->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-ruble-sign"></i>
                    </div>
                    <h3 class="h3">Узнаете, как начать работу с Китаем</h3>
                    <p>
                        Культурные особенности и тонкости законодательства КНР, способы таможенного оформления продукции, правила ведения переговоров и делового этикета для получения выгодных условий от поставщиков и др.
                    </p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-graduation-cap"></i>
                    </div>
                    <h3 class="h3">Поймете, как устроен товарный бизнес с Китаем</h3>
                    <p>Какая продукция актуальна, как самостоятельно искать поставщиков и производителей, как проверить деловую репутацию потенциальных партнеров, как заказывать товар под собственным брендом и др.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-key"></i>
                    </div>
                    <h3 class="h3">
                        Получите возможность личного наставничества от экспертов - китаистов
                    </h3>
                    <p>Спикеры курса - практики, которые знают правила и особенности ведения бизнеса с КНР, работают с  китайскими партнерами и проживают непосредственно на территории Китая</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-money-check-alt"></i>
                    </div>
                    <h3 class="h3">
                        Сможете увеличить доход компании
                    </h3>
                    <p>Применяя знания, полученные на курсе, вы сможете в кратчайшие сроки запустить бизнес с Китаем и увеличить доход от 0 до 3 млн рублей в месяц</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
