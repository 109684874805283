<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="https://opora.ru/obrazovanie/"><img src="assets/img/opora_logo_flag.png"/></a></h3>
        <ul>
            <li><a href="https://t.me/+tw2Apy-Hb7hkODUy" target="_blank" class="telegram fab fa-telegram" style="font-size:48px"></a></li>
        </ul>
    </div>
</footer>

<ngx-scrolltop
position="left"
></ngx-scrolltop>
