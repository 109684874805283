<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <!--<i class="far fa-smile"></i>
                    <h3><span [countUp]="1548">00</span></h3>-->
                    <h3 class="opora_title">Почему стоит выбрать курс «ОПОРЫ РОССИИ»?</h3>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span [countUp]="8">00</span></h3>
                    <p>Представителей «ОПОРЫ РОССИИ» в КНР</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h3><span [countUp]="5">00</span></h3>
                    <p>Проведенных бизнес-миссий «ОПОРЫ РОССИИ» в КНР только в 2024 году</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-award"></i>
                    <h3><span [countUp]="60">00</span></h3>
                    <p>Заключенных контрактов с партнерами из КНР по итогам бизнес-миссий</p>
                </div>
            </div>
        </div>
    </div>
</section>
