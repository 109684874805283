<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“У нас заметно возросло научное и научно-техническое сотрудничество. От обработки материалов до активной работы российского университета на территории Китая. Это уже воспитание нового поколения и инвесторов, и технологов, и ученых.”</p>
                        <div class="info">
                            <h3>Алексей Маслов</h3>
                            <span>
                                Профессор, Доктор исторических <br />наук, Преподаватель высшей школы <br />бизнеса МГУ Директор института стран Азии и Африки
                            </span>
                            <img src="assets/img/experts/small_size/maslov.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“В текущих условиях приходится кардинально трансформировать ВЭД. В этом процессе самое главное — в первую очередь самим предпринимателям правильно организоваться. Сегодня необходимо выявить волнующие бизнес вопросы и собрать свод рекомендаций для уполномоченных органов власти по вопросам  <br />ВЭД.”</p>
                        <div class="info">
                            <h3>Сергей Борисов</h3>
                            <span>Председатель Попечительского совета «ОПОРЫ РОССИИ»</span>
                            <img src="assets/img/experts/small_size/borisov.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Мы работаем сообща, наши бизнесмены заключают контракты с китайскими партнерами, бизнес КНР локализует свое производство в России, работают совместные промышленные, туристические проекты, также в ходе общения мы узнаем о программах поддержки и развития

                        а друг друга.”</p>
                        <div class="info">
                            <h3>Александр Калинин</h3>
                            <span>Президент Общероссийской <br />организации малого и среднего <br />предпринимательства «ОПОРА РОССИИ»</span>
                            <img src="assets/img/experts/small_size/kalinin.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Выход на китайский рынок через онлайн платформы - наиболее перспективный путь развития. И то, что, открыв магазин на AliExpress и вложив туда силы, время, старания и определенные навыки, можно заработать миллион, подтверждено моим собственным опытом.”</p>
                        <div class="info">
                            <h3>Илона Горшенева-Долунц</h3>
                            <span>представитель «ОПОРЫ РОССИИ» в КНР (провинция Гуандун)</span>
                            <img src="assets/img/experts/small_size/ilona.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <!--
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Самые лучшие китайские компании и города стали именно такими потому, что у них есть рынок, технологии, специалисты, связи на Западе в широком смысле слова. Им есть что терять из-за работы с Россией и русскими, и они не хотят потерь.”</p>
                        <div class="info">
                            <h3>Александр Зайнигабдинов</h3>
                            <span>Руководитель пекинского офиса CHINA WINDOW CONSULTING GROUP</span>
                            <img src="assets/img/experts/small_size/zanigabdinov.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                -->
            </owl-carousel-o>
        </div>
    </div>
</section>
