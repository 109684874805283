<div id="about" class="about-area diplomsContainer">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content ">
                    <div class="description">
                        <h2 class="header">Диплом о профессиональной переподготовке</h2>
                        <p>
                            После окончания курса и выполнения финального теста вы получите удостоверение о дополнительном образовании, выданное «ОПОРОЙ РОССИИ».
                            Общероссийская общественная организация малого и среднего предпринимательства «ОПОРА РОССИИ» имеет образовательную лицензию, выданную Департаментом образования и науки города Москвы
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-video">
                    <img src="assets/img/cert1.jpg" alt="about">
                    <div class="video-btn">
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
