<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <span class="h3c">НАЧНИ РАБОТАТЬ С КИТАЕМ</span>
                    <h1>CHINA <span>BUSINESS</span> SCHOOL</h1>
                    <p class="pc"> «ОПОРА РОССИИ» совместно с Институтом стран Азии и Африки МГУ имени М.В. Ломоносова запускает образовательный курс <span class="oporaTitle">по работе с Китаем</span></p>
                    <a (click)="onClick('welcome')" class="btn btn-primary">Начать</a>
                    <!--<a (click)="onClick('pricing')" class="btn btn-primary view-work">Курсы</a>-->

                </div>
            </div>
        </div>
    </div>
    <a id="tg-banner" href="https://t.me/+tw2Apy-Hb7hkODUy" class="telegram">
        <div class="grid uk-grid">
            <!-- Изображение -->
            <div class="col-auto">
                <img src="assets/img/tlogo.svg" style="width: 46px;">
            </div>
            <!-- Текст -->
            <div class="col-expand">
                Подпишитесь на Telegram-канал<br>
                <span class="widget_text_main">«ОПОРЫ РОССИИ»</span>
            </div>
        </div>
    </a>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-funfacts></app-funfacts>

<!--<app-why-we-different></app-why-we-different>-->

<app-team></app-team>

<app-cta></app-cta>

<!--<app-work></app-work>-->

<!--<app-services></app-services>

<app-strategy></app-strategy>-->

<app-who-we-are></app-who-we-are>

<education-system></education-system>

<diploms></diploms>

<app-feedback></app-feedback>

<our-experts></our-experts>

<app-pricing></app-pricing>

<app-blog></app-blog>

<china-mission></china-mission>

<app-faq></app-faq>

<app-contact></app-contact>



<!--
<app-skill></app-skill>

<app-how-we-work></app-how-we-work>

<app-partner></app-partner>

<app-subscribe></app-subscribe>
-->
