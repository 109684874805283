<div id="education_system" class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Программа <span>обучения</span></h2>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="module1" checked>
            <label for="tab2">Модуль 1</label>
            <input type="radio" name="tabset" id="tab3" aria-controls="module2">
            <label for="tab3">Модуль 2</label>
            <input type="radio" name="tabset" id="tab4" aria-controls="module3">
            <label for="tab4">Модуль 3</label>
            <input type="radio" name="tabset" id="tab5" aria-controls="module4">
            <label for="tab5">Модуль 4</label>
            <input type="radio" name="tabset" id="tab6" aria-controls="module5">
            <label for="tab6">Модуль 5</label>
            <input type="radio" name="tabset" id="tab7" aria-controls="module6">
            <label for="tab7">Модуль 6</label>
            <input type="radio" name="tabset" id="tab8" aria-controls="module7">
            <label for="tab8">Модуль 7</label>
            <input type="radio" name="tabset" id="tab9" aria-controls="module8">
            <label for="tab9">Модуль 8</label>
            <input type="radio" name="tabset" id="tab10" aria-controls="module9">
            <label for="tab10">Модуль 9</label>
            <input type="radio" name="tabset" id="tab11" aria-controls="module10">
            <label for="tab11">Модуль 10</label>
            <input type="radio" name="tabset" id="tab12" aria-controls="module11">
            <label for="tab12">Модуль 11</label>
            <input type="radio" name="tabset" id="tab13" aria-controls="module12">
            <label for="tab13">Модуль 12</label>
            <input type="radio" name="tabset" id="tab14" aria-controls="module13">
            <label for="tab14">Модуль 13</label>


            <div class="tab-panels">

                <div class="tab-panel">
                    <!--class="tab-panel"-->
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module1.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Вводная теоретическая часть</h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Общее представление о Китае и его экономике:</span> основные цифры и показатели, которые формируют основу понимания рынка.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание ключевых направлений бизнеса с Китаем:</span>  основные возможности импорта и экспорта.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Осознание преимуществ сотрудничества с Китаем:</span> от низкой себестоимости товаров до высоких объемов производства.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание актуальных трендов бизнеса:</span> перспективные направления на ближайшие 5 лет.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Пошаговое руководство для старта:</span> базовые шаги и подготовка для начала работы с Китаем.
</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module2" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Культурные особенности и устройство китайского бизнес-ландшафта
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> <span class="bold">Глубокое понимание культурных различий:</span> особенности менталитета китайских партнеров и их влияние на деловое общение.</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки построения доверительных отношений:</span> использование концепции «Гуаньси» и учет важности «Мяньцзы» (китайской концепции лица).
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание сходств и различий культур России и Китая Полную подготовку к переговорам с китайскими партнерами:</span> психологическую, информационную, содержательную и организационную.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Практические рекомендации по успешной коммуникации:</span> адаптация к культурным особенностям и эффективное ведение бизнеса с учетом китайского бизнес-ландшафта.
</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module3" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module3.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Практика импорт
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Базовые знания по импорту товаров:</span> понимание всех ключевых шагов, необходимых для ввоза товаров из Китая.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки расчета себестоимости Алгоритм работы с Китаем:</span> пошаговый план, обеспечивающий успешный импорт товара.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Разбор документации:</span> полный список документов для безопасной офлайн-торговли, продаж в интернете и на маркетплейсах.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание сроков и стоимости доставки:</span> основные факторы, влияющие на эти параметры, и как их оптимизировать.
</li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знания об отгрузке и закупках:</span> что важно учитывать при отгрузке товара и как эффективно планировать закупки в Китае.
</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module4" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    E-commerce. Работа с Китаем через электронные платформы
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание особенностей китайского интернета Знакомство с китайскими онлайн-платформами:</span> обзор китайских аналогов мировых платформ.
</li>
                                    <li><i class="fas fa-check"></i> <span class="bold">Навыки работы на китайских электронных платформах:</span> регистрация, поиск поставщиков, ведение переговоров, оплата и проверка надежности поставщиков.</li>
                                    <li><i class="fas fa-check"></i> <span class="bold">Эффективное использование Baidu:</span> освоение главного китайского поисковика для поиска информации и поставщиков.</li>
                                    <li><i class="fas fa-check"></i> <span class="bold">Умение составлять грамотные заявки.</span></li>


                                    <!--
                                    <li>
                                        <i class="fas fa-check"></i> Как самостоятельно зарегистрироваться, искать, общаться, оплачивать, оценивать поставщика с точки зрения надежности и качества на следующих площадках:
                                        <ul class="sub_list">
                                            <li><i class="fas fa-minus"></i> AliExpress</li>
                                            <li><i class="fas fa-minus"></i> TaoBao</li>
                                            <li><i class="fas fa-minus"></i> AliBaba</li>
                                            <li><i class="fas fa-minus"></i> 1688</li>
                                            <li><i class="fas fa-minus"></i> BaiDu</li>
                                        </ul>
                                    </li>
                                    -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module5" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module5.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Переговоры с китайскими бизнес-партнерами, заключение сделок. HR - рынок труда в Китае
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание ключевых отличий китайских поставщиков от российских.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание тем, которых следует избегать при общении с китайскими партнерами.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Алгоритм подготовки к переговорам: </span> шаги для успешного взаимодействия с китайскими бизнес-партнерами.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Умение оценивать риски сотрудничества:</span> понимание показателей, которые сигнализируют об опасности работы с поставщиком.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки предотвращения и решения проблем с браком:</span> как минимизировать риски и урегулировать споры.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Анализ причин неудач российского бизнеса в Китае.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Основы управления персоналом в Китае.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки поиска специалистов для работы с Китаем.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Карту компетенций российского персонала:</span> понимание навыков и качеств, необходимых для успешной работы в китайской бизнес-среде.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module6" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module6.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Создание своего присутствия в Китае
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание типов предприятий в Китае:</span> как выбрать подходящий формат для ведения бизнеса.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Алгоритм регистрации предприятия:</span> пошаговое руководство по открытию компании в Китае.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Практические рекомендации по созданию компании:</span> ключевые аспекты, которые необходимо учитывать для успешного запуска и ведения бизнеса.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module7" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module7.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Банковские платежи
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание работы банковских систем:</span> какие банки используются для осуществления переводов в Китай.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки работы с платежными схемами:</span> особенности расчетов для санкционных и несанкционных товаров.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание способов оплаты через платежных агентов:</span> как использовать их для удобных и безопасных транзакций.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание способов оплаты через юридическое лицо в Китае.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Пошаговый алгоритм сотрудничества с зарубежными поставщиками:</span> от первой оплаты до завершения сделки.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module8" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module8.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Защита прав интеллектуальной собственности в Китае
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание процесса защиты авторских прав в Китае:</span> как эффективно защищать свои разработки.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание процесса регистрации авторского свидетельства на программное обеспечение:</span> пошаговая инструкция по оформлению.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки регистрации патентов в Китае:</span> как защитить свои инновации и избежать нарушений со стороны третьих лиц.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module9" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module9.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Об ошибках и потерях при работе с Китаем
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание ТОП-10 самых частых потерь при работе с Китаем и способы их предотвращения.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки защиты от налоговых проверок:</span> как правильно оформлять документы и соблюдать налоговые требования.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Методы предотвращения брака:</span> эффективные шаги для минимизации производственных дефектов.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Способы предотвращения потерь при транспортировке:</span> что учитывать при планировании логистики.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Рекомендации по отправке грузов из Китая:</span> ключевые аспекты, которые важно соблюдать для успешной поставки.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знания о страховании груза:</span> как оформить страховку для защиты от финансовых рисков.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Методы проверки качества продукции:</span> контроль на этапе производства и перед отправкой для гарантии соответствия требованиям.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module10" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module10.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Как выбрать нишу, где нет или мало конкурентов
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание концепций «голубого» и «алого» океана.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знание способов поиска «голубого океана»:</span> инструменты и стратегии для работы с Китаем и минимизации конкуренции.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Инструменты для выбора неконкурентной ниши:</span> как находить перспективные продукты для маркетплейсов и интернет-магазинов.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Осознание важности развития собственного бренда.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки выбора правильной ниши и продукта:</span> как анализировать рынок и выбирать прибыльные направления для продаж.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Умение выявлять потребности клиентов:</span> определение "болей» аудитории и создание товаров, которые станут популярными.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module11" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module11.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Как заказать товар под собственным брендом
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание контрактного производства:</span> что это такое, какие его преимущества и как эффективно использовать для своего бизнеса.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знания об OEM-производстве:</span> что представляет собой OEM, его преимущества и недостатки при заказе товаров под собственным брендом.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Разбор ODM-производства:</span> как работает ODM, его плюсы и минусы для создания уникальной продукции.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Алгоритм выбора производителя:</span> пошаговое руководство по выбору подходящего производителя для контрактного производства.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module12" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module12.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Основы внешнеэкономической деятельности ВЭД
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Список основных документов ВЭД.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знания о ЕАЭС:</span> основные характеристики и роль Евразийского экономического союза в международной торговле.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Алгоритм ввоза продукции:</span> пошаговая инструкция по вводу товаров на территорию страны.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Процесс прибытия товаров в Россию:</span> как товары поступают на таможенную территорию Российской Федерации.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Перечень необходимых документов и информационных ресурсов для таможенного ввоза.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Информация о сертификации продукции.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Знания о «Честном знаке»:</span> как работает система маркировки товаров в России для обеспечения их подлинности и безопасности.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="module13" class="tab-panel">
                    <div class="row align-items-start">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/module13.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>
                                    Как продавать товары на маркетплейсах
                                </h3>
                                <p class="additional_text">После прохождения данного модуля вы получите следующее:</p>
                                <ul>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Понимание принципов работы российских маркетплейсов и их особенностей.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки анализа ниш и маркетплейсов:</span> как выбирать прибыльные ниши и наиболее подходящие платформы для продажи.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Руководство по регистрации и расчету юнит-экономики.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Умение готовить карточки товаров.</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Навыки поставки товаров:</span> как организовать процесс доставки товара на маркетплейс и поддержание товарных запасов.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Методы продвижения товаров:</span> как вывести товары в ТОП поисковой выдачи на маркетплейсах и привлечь больше клиентов.
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i> <span class="bold">Способности к анализу продаж:</span> как анализировать продажи, выявлять тренды и прогнозировать будущие поставки для оптимизации бизнеса.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
